<template>
  <div class="row">
    <div class="col-lg-6 mb-4 mb-lg-0">
      <div class="lk__block">
        <div class="lk__block-title">04. График посещений менеджера</div>
        <calendar is-expanded/>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="lk__block">
        <div class="lk__block-title">05. График доставок</div>
        <calendar
          :attributes="attrs"
          is-expanded
          :from-page="{ month: 10, year: 2021 }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Calendar } from 'v-calendar'
export default {
  components: {
    Calendar
  },
  setup () {
    return {
      attrs: [
        {
          description: 'Take Noah to basketball practice.',
          isComplete: false,
          dates: { start: new Date(2021, 11, 14), end: new Date(2021, 11, 18) },
          color: 'red'
        }
      ]
    }
  }
}
</script>
